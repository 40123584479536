<template>
  <List
    :title="'Step logs - Step'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="true"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    :extra-actions="extraActions"
    :total="total"
    :perPage="perPage"
    :currentPage="currentPage"
    :pagination="false"
    @optionSel="handleOption"
    @create="handleCreate"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
    @selectFilter="handleTabFilter"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Step Logs - Steps",
      entries: [],
      filteredEntries: [],
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      tabFilters: [
        {
          name: "Steps",
          filter: "",
          isActive: true,
        }
      ],
      isActive: 0,
      headItems: ["Name", "Measurements"],
      bodyItems: [],
      editOptions: ["see"],
      search_key: "",
      canCreate: [],
      org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List
  },

  mixins: [timeSince, isMobile, api],

  watch: {
    currentPage() {
      this.getData();
    },
    entries() {
      this.fillBodyItems();
    },

    search_key() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }

        this.getData();
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
  },

  created() {
    this.getData()
  },
  
  methods: {

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        return [
          {
            template: "normal",
            id: item.id,
            data: item.title || "undefined",
          },
          {
            template: "normal",
            data: item.measurement.length || "0",
          }
        ]
      });
    },

    async getData() {
        this.loaded = false;
        this.entries = [];

        const response = await axiosInstance.get(`step?troubleshoot_id=${this.$route.params.guide_id}`)
        this.loaded = true

        let responseData = response.data.data;
        this.entries = responseData.data;
    },

    handleOption(data) {
      switch (data[0]) {
        case "see":
          this.$router.push(`${this.$route.path}/${data[1]}`)
          break;
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },
  },
};
</script>