<template>
  <List
    :title="'Step Logs - Guide'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="true"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    :extra-actions="extraActions"
    :total="total"
    :perPage="perPage"
    :currentPage="currentPage"
    @optionSel="handleOption"
    @create="handleCreate"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
    @selectFilter="handleTabFilter"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages="pages"
    @goToFirst="currentPage = 1"
    @goToLast="currentPage = pages"
    @goToPage="handlePage"
    @changePage="handlePageChange"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Step Logs - Guides",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      tabFilters: [
        {
          name: "Guides",
          filter: "",
          isActive: true,
        }
      ],
      isActive: 0,
      headItems: ["Name", "Steps"],
      bodyItems: [],
      editOptions: ["see"],
      search_key: "",
      canCreate: [],
      org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination
  },

  mixins: [timeSince, isMobile, api],

  watch: {
    currentPage() {
      this.getData();
    },
    entries() {
      this.fillBodyItems();
    },

    search_key() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }

        this.getData();
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),

    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
  },

  created() {
    this.getData()
  },
  
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    handlePage(to) {
        this.currentPage = to
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        return [
          {
            template: "normal",
            id: item.id,
            data: item.title || "undefined",
          },
          {
            template: "normal",
            data: item.steps_count || "0",
          }
        ]
      });
    },

    async getData() {
        this.loaded = false;
        this.entries = [];
        this.perPage = 0;
        this.total = 0;
        this.pages = 0;

        const response = await axiosInstance.get( `guide?org_id=${this.org.id}&searchKey=${this.search_key}&page=${this.currentPage}`)
        this.loaded = true

        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = responseData.per_page;
        this.total = responseData.total;
        this.pages = responseData.last_page;
    },

    handleOption(data) {
      switch (data[0]) {
        case "see":
          this.$router.push(`${this.$route.path}/${data[1]}`)
          break;
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },
  },
};
</script>